import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';

import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';

import css from './RequiresVerificationPage.module.css';
import { logout } from '../../ducks/Auth.duck';

export const RequiresVerificationPageComponent = props => {
  const dispatch = useDispatch()

  const { intl, scrollingDisabled, currentUser } = props;

  const title = intl.formatMessage({
    id: 'RequiresVerificationPage.title',
  });

  useEffect(() => {
    const { requiresVerification } = currentUser?.attributes?.profile?.protectedData || {}
    if (requiresVerification) {
      dispatch(logout())
    }
  }, [currentUser])


  return <Page title={title} scrollingDisabled={scrollingDisabled}>
    < LayoutSingleColumn >
      <LayoutWrapperTopbar>
        <TopbarContainer />
      </LayoutWrapperTopbar>
      <LayoutWrapperMain>
        <div className={css.root}>
          <div className={css.content}>
            <h1 className={css.heading}>
              <FormattedMessage id="RequiresVerificationPage.heading" />
            </h1>
            <p className={css.description}>
              <FormattedMessage id="RequiresVerificationPage.description" />
            </p>
            <p className={css.description}>
              <FormattedMessage id="RequiresVerificationPage.communityReachOut" />
            </p>
          </div>
        </div>
      </LayoutWrapperMain>
      <LayoutWrapperFooter>
        <Footer />
      </LayoutWrapperFooter>
    </LayoutSingleColumn >
  </Page >
}

RequiresVerificationPageComponent.defaultProps = {
  staticContext: {},
};

const { bool, func, object, shape } = PropTypes;

RequiresVerificationPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // context object from StaticRouter, injected by the withRouter wrapper
  staticContext: object,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser: state.user.currentUser,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const RequiresVerificationPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(RequiresVerificationPageComponent);

export default RequiresVerificationPage;
