import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaTiktok.module.css';

const IconSocialMediaTiktok = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
      <path
        fill="#4a4a4a"
        fillRule="evenodd"
        d="M3.542.708A2.833 2.833 0 0 0 .708 3.542v9.916a2.833 2.833 0 0 0 2.834 2.834h9.916a2.833 2.833 0 0 0 2.834-2.834V3.542A2.833 2.833 0 0 0 13.458.708H3.542Zm5.199 2.48h1.69c0 1.133.958 2.415 2.416 2.415v1.69c-1.004-.07-1.861-.234-2.415-.725v4.347c0 1.207-.955 2.898-3.139 2.898-2.668 0-3.14-2.416-3.14-3.14 0-.724.426-3.14 3.38-3.14v1.692c-.772-.164-1.69.482-1.69 1.448 0 1.208.725 1.45 1.45 1.45.724 0 1.449-.725 1.449-1.45V3.187Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

IconSocialMediaTiktok.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaTiktok.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaTiktok;
