import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaPinterest.module.css';

const IconSocialMediaPinterest = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
      <path
        fill="#4a4a4a"
        fillRule="evenodd"
        d="M3.542.708A2.833 2.833 0 0 0 .708 3.542v9.916a2.833 2.833 0 0 0 2.834 2.834h9.916a2.833 2.833 0 0 0 2.834-2.834V3.542A2.833 2.833 0 0 0 13.458.708H3.542ZM8.52 2.833c-2.478 0-4.225 1.921-4.419 3.837-.107 1.066.104 2.02.54 2.657a.772.772 0 0 0 1.275-.872c-.179-.262-.36-.825-.278-1.63.119-1.168 1.24-2.446 2.882-2.446 1.288 0 1.99.465 2.377.999.41.568.543 1.327.462 2-.084.696-.297 1.402-.637 1.89-.316.452-.704.682-1.259.627-.444-.045-.648-.286-.776-.588a1.891 1.891 0 0 1-.134-.546c.149-.513.27-.992.333-1.386a2.79 2.79 0 0 0 .038-.788c-.024-.195-.146-.769-.753-.903a1.263 1.263 0 0 0-1.241.408c-.3.343-.41.805-.41 1.271 0 .228.059.454.111.622.057.18.13.36.196.51.041.092.08.177.117.252l-.03.096a78.861 78.861 0 0 1-.737 2.22l-.105.304a93.809 93.809 0 0 0-.606 1.79.772.772 0 1 0 1.471.473c.165-.51.373-1.114.595-1.757l.106-.307.255-.746c.337.31.797.55 1.417.613 1.23.123 2.118-.477 2.68-1.283.538-.77.802-1.75.903-2.588.116-.962-.054-2.135-.744-3.09-.715-.99-1.914-1.639-3.63-1.639Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

IconSocialMediaPinterest.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaPinterest.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaPinterest;
