import React from 'react';
import { string } from 'prop-types';

const IconCart = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      viewBox="0 0 480 480"
    >
      <path d="M475.71 435.272h-.04l-35.744-300.216a8 8 0 00-7.944-7.056h-88v-24c0-57.438-46.562-104-104-104s-104 46.562-104 104v24h-88a8 8 0 00-7.944 7.056L4.294 435.272c-2.611 21.937 13.055 41.836 34.992 44.448 1.572.187 3.153.281 4.736.28h391.96c22.091.005 40.004-17.9 40.008-39.992a39.875 39.875 0 00-.28-4.736zM151.982 104c0-48.601 39.399-88 88-88s88 39.399 88 88v24h-176v-24zm301.912 351.936A24.002 24.002 0 01435.982 464H44.022c-13.255.004-24.003-10.737-24.008-23.992 0-.949.056-1.897.168-2.84L55.086 144h80.896v56a8 8 0 0016 0v-56h176v56a8 8 0 0016 0v-56h80.896l34.904 293.168a23.999 23.999 0 01-5.888 18.768z" />
    </svg>
  );
};

IconCart.propTypes = {
  className: string,
};

export default IconCart;
