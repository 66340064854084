import { types as sdkTypes } from '../util/sdkLoader';
import config from '../config';

const { Money } = sdkTypes;

/**
 * Gets the first word from a given string.
 * @param {string} string - The input string.
 * @returns {string} The first word of the input string.
 */
export const getFirstWordFromString = string => string.split(' ')[0];

/**
 * Sums up the total price of all listings in a cart.
 * @param {array} listings - An array of listings.
 * @returns {Money|null} The total sum of prices as a Money object, or null if listings are empty.
 */
export const sumCartTotal = listings => {
  const sum = listings?.reduce(function(prev, current) {
    return prev + +current.attributes.price.amount;
  }, 0);

  return sum ? new Money(sum, config.currency) : null;
};

/**
 * Adds quantity information to a listing from the current user's cart.
 * @param {object} listing - The listing object.
 * @param {array} currentUserCart - The current user's cart.
 * @returns {object} The listing object with quantity information added.
 */
export const addQuantityToListing = (listing, currentUserCart) => {
  const listingInCart = currentUserCart?.find(i => i.listingId === listing.id.uuid);
  const quantity = listingInCart?.quantity || 1;
  const variants = listingInCart?.variants;

  const colorMaybe = variants?.color ? { color: variants?.color } : {};
  const sizeMaybe = variants?.size ? { size: variants.size?.key } : {};

  const variantsMaybe =
    variants?.color || variants?.size ? { variants: { ...sizeMaybe, ...colorMaybe } } : {};

  const price = variants?.size
    ? new Money(variants.size.price.amount, variants.size.price.currency)
    : listing.attributes.price;

  return {
    ...listing,
    ...variantsMaybe,
    quantity,
    attributes: {
      ...listing.attributes,
      price,
    },
  };
};

/**
 * Validates and adds quantity information to other listings except the first one in the cart.
 * @param {string} firstListingId - The ID of the first listing in the cart.
 * @param {array} listings - An array of listings.
 * @param {array} currentUserCart - The current user's cart.
 * @returns {array|null} An array of other listings with quantity information added, or null if listings are empty.
 */
export const validateOtherListings = (firstListingId, listings, currentUserCart) => {
  if (!listings) {
    return null;
  }

  const otherListings = listings?.filter(l => l.id.uuid !== firstListingId);
  const hasOtherListings = otherListings?.length > 0;

  return hasOtherListings ? otherListings.map(l => addQuantityToListing(l, currentUserCart)) : null;
};

/**
 * Calculates the total price of a listing based on its price and quantity.
 * @param {object} price - The price object of the listing.
 * @param {number} quantity - The quantity of the listing.
 * @returns {Money} The total price of the listing as a Money object.
 */
export const calculateListingPrice = (price, quantity) =>
  new Money(price.amount * quantity, price.currency);
