import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { Modal } from '..';
import ShippingAddressForm from './ShippingAddressForm';
import classNames from 'classnames';

import css from './ShippingAddressModal.module.css';

const ShippingAddressModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    modalDescription,
    onManageDisableScrolling,
    onSubmit,
    inProgress,
    shippingId,
    initialValues,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'ShippingAddressModal.close' });

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="ShippingAddressModal.title" />
      </p>
      <p className={css.modalMessage}>{modalDescription}</p>
      <ShippingAddressForm
        intl={intl}
        onSubmit={onSubmit}
        inProgress={inProgress}
        fieldId={shippingId}
        initialValues={initialValues ? { ...initialValues } : {}}
      />
    </Modal>
  );
};

const { string, func, bool } = PropTypes;

ShippingAddressModal.defaultProps = {
  className: null,
  rootClassName: null,
  shippingId: null,
  modalDescription: null,

  // Form
  inProgress: null,
  onSubmit: null,
};

ShippingAddressModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  shippingId: string,
  modalDescription: string,

  // Form
  inProgress: bool,
  onSubmit: func.isRequired,
};

export default injectIntl(ShippingAddressModal);
