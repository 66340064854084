/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser, isUserSeller } from '../../../util/data';

import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../../components';

import MenuAccordion from './MenuAccordion/MenuAccordion';
import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const { isAuthenticated, currentPage, currentUser, notificationCount, onLogout } = props;

  const user = ensureCurrentUser(currentUser);
  const userSeller = isUserSeller(user);

  const signup = (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <FormattedMessage id="TopbarMobileMenu.signupLink" />
    </NamedLink>
  );

  const login = (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <FormattedMessage id="TopbarMobileMenu.loginLink" />
    </NamedLink>
  );

  const signupOrLogin = (
    <span className={css.authenticationLinks}>
      <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
    </span>
  );

  const nonLoggedinLinks = (
    <div className={css.root}>
      <div className={css.content}>
        <div className={css.authenticationGreeting}>
          <FormattedMessage
            id="TopbarMobileMenu.unauthorizedGreeting"
            values={{ lineBreak: <br />, signupOrLogin }}
          />
        </div>
      </div>
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="SignupPage">
          <FormattedMessage id="TopbarMobileMenu.signupLink" />
        </NamedLink>
      </div>
    </div>
  );

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      {isAuthenticated ? (
        <AvatarLarge className={css.avatar} user={currentUser} />
      ) : (
        nonLoggedinLinks
      )}
      <div className={css.content}>
        {isAuthenticated ? (
          <React.Fragment>
            <span className={css.greeting}>
              <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
            </span>
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <FormattedMessage id="TopbarMobileMenu.logoutLink" />
            </InlineTextButton>
          </React.Fragment>
        ) : null}
        <MenuAccordion />
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: userSeller ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('CartPage'))}
          name="CartPage"
        >
          <FormattedMessage id="TopbarMobileMenu.shoppingCartLink" />
        </NamedLink>
        {userSeller ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>
        ) : null}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('BulkUploadPage'))}
          name="BulkUploadPage"
        >
          <FormattedMessage id="TopbarDesktop.bulkUploadLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(
            css.navigationLink,
            css.lastNavigationLink,
            currentPageClass('AccountSettingsPage')
          )}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>

        <div className={css.spacer} />
      </div>
      {isAuthenticated ? (
        <div className={css.footer}>
          {userSeller ? (
            <NamedLink className={css.createNewListingLink} name="NewListingPage">
              <FormattedMessage id="TopbarMobileMenu.newListingLink" />
            </NamedLink>
          ) : (
            <NamedLink className={css.createNewListingLink} name="SearchPage">
              <FormattedMessage id="TopbarMobileMenu.searchLink" />
            </NamedLink>
          )}
        </div>
      ) : null}
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
