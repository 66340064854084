import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconMagicWand, IconWallet } from '../../components';
import classNames from 'classnames';

import css from './CartPage.module.css';

const itemPanel = (heading, text, icon) => {
  return (
    <li className={css.listItem}>
      {icon}
      <div>
        <h3 className={css.itemHeading}>{heading}</h3>
        <p className={css.itemText}>{text}</p>
      </div>
    </li>
  );
};

const CartPanel = props => {
  const { rootClassName, className, intl } = props;
  const classes = classNames(rootClassName || css.cartPanel, className);

  // Easy checkout
  const easyCheckoutHeading = intl.formatMessage({
    id: 'CartPage.easyCheckoutHeading',
  });
  const easyCheckoutText = intl.formatMessage({ id: 'CartPage.easyCheckoutText' });

  // Secure payment
  const securePaymentHeading = intl.formatMessage({ id: 'CartPage.securePaymentHeading' });
  const securePaymentText = intl.formatMessage({ id: 'CartPage.securePaymentText' });
  return (
    <div className={classes}>
      <h3 className={css.panelHeading}>
        <FormattedMessage id="CartPage.panelHeading" />
      </h3>
      <ul className={css.panelList}>
        {itemPanel(
          easyCheckoutHeading,
          easyCheckoutText,
          <IconMagicWand className={css.itemIcon} />
        )}
        {itemPanel(
          securePaymentHeading,
          securePaymentText,
          <IconWallet className={css.itemIcon} />
        )}
      </ul>
    </div>
  );
};

CartPanel.defaultProps = {
  rootClassName: null,
  className: null,
};

CartPanel.propTypes = {
  rootClassName: string,
  className: string,
};

export default CartPanel;
