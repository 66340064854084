import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities, setLocalStorageItemAsync } from '../../util/data';
import { fetchCurrentUserCartListings, fetchGuestUserCartListings } from '../../ducks/user.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { v4 as uuidv4 } from 'uuid';
import config from '../../config';

const MINIMAL_LISTING_STOCK = config.listingStockReservationQuantity;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const FETCH_LISTINGS_REQUEST = 'app/WishListPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/WishListPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/WishListPage/FETCH_LISTINGS_ERROR';

export const ADD_TO_CART_REQUEST = 'app/CartPage/ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'app/CartPage/ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_ERROR = 'app/CartPage/ADD_TO_CART_ERROR';

export const REMOVE_FROM_CART_REQUEST = 'app/CartPage/REMOVE_FROM_CART_REQUEST';
export const REMOVE_FROM_CART_SUCCESS = 'app/CartPage/REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_ERROR = 'app/CartPage/REMOVE_FROM_CART_ERROR';

export const UPDATE_QUANTITY_REQUEST = 'app/CartPage/UPDATE_QUANTITY_REQUEST';
export const UPDATE_QUANTITY_SUCCESS = 'app/CartPage/UPDATE_QUANTITY_SUCCESS';
export const UPDATE_QUANTITY_ERROR = 'app/CartPage/UPDATE_QUANTITY_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchListingsInProgress: false,
  fetchListingsError: null,
  cartResultIds: [],
  addToCartInProgress: false,
  addToCartError: null,
  removeFromCartInProgress: false,
  removeFromCartError: null,
  currentRemoveListingIds: [],
  updateQuantityInProgress: false,
  updateQuantityError: null,
};

const resultIds = data => data.data.map(l => l.id);

const cartPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsError: null,
        cartResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        cartResultIds: resultIds(payload.data),
        fetchListingsInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, fetchListingsInProgress: false, fetchListingsError: payload };

    case ADD_TO_CART_REQUEST:
      return {
        ...state,
        addToCartInProgress: true,
      };
    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        addToCartInProgress: false,
      };
    case ADD_TO_CART_ERROR:
      return {
        ...state,
        addToCartInProgress: false,
        addToCartError: payload,
      };

    case REMOVE_FROM_CART_REQUEST:
      return {
        ...state,
        currentRemoveListingIds: [...state.currentRemoveListingIds, payload],
        removeFromCartInProgress: true,
      };
    case REMOVE_FROM_CART_SUCCESS:
      return {
        ...state,
        cartResultIds: payload,
        removeFromCartInProgress: false,
      };
    case REMOVE_FROM_CART_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        removeFromCartInProgress: false,
        removeFromCartError: payload,
      };

    case UPDATE_QUANTITY_REQUEST:
      return {
        ...state,
        updateQuantityInProgress: true,
        updateQuantityError: null,
      };
    case UPDATE_QUANTITY_SUCCESS:
      return {
        ...state,
        updateQuantityInProgress: false,
        updateQuantityError: null,
      };
    case UPDATE_QUANTITY_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        updateQuantityInProgress: false,
        updateQuantityError: payload,
      };

    default:
      return state;
  }
};

export default cartPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const queryListingsRequest = () => ({
  type: FETCH_LISTINGS_REQUEST,
});
export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const addToCartRequest = () => ({
  type: ADD_TO_CART_REQUEST,
});
export const addToCartSuccess = () => ({
  type: ADD_TO_CART_SUCCESS,
});
export const addToCartError = e => ({
  type: ADD_TO_CART_ERROR,
  error: true,
  payload: e,
});

export const removeFromCartRequest = listingId => ({
  type: REMOVE_FROM_CART_REQUEST,
  payload: listingId,
});
export const removeFromCartSuccess = listingIds => ({
  type: REMOVE_FROM_CART_SUCCESS,
  payload: listingIds,
});
export const removeFromCartError = e => ({
  type: REMOVE_FROM_CART_ERROR,
  error: true,
  payload: e,
});

export const updateQuantityRequest = (listingId, newQuantity) => ({
  type: UPDATE_QUANTITY_REQUEST,
  payload: { listingId, newQuantity },
});
export const updateQuantitySuccess = () => ({
  type: UPDATE_QUANTITY_SUCCESS,
});
export const updateQuantityError = e => ({
  type: UPDATE_QUANTITY_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryListings = () => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  return sdk.currentUser
    .show()
    .then(res => {
      const cartItems = res.data.data.attributes?.profile?.publicData?.cart;
      const cartItemListingIds = cartItems ? cartItems.map(i => i.listingId) : [];

      // Listing query params
      const params = {
        ids: cartItemListingIds,
        minStock: MINIMAL_LISTING_STOCK,
        include: ['author', 'author.profileImage', 'images', 'currentStock'],
        'fields.listing': ['title', 'price', 'publicData'],
        'fields.image': [
          // Scaled variants for large images
          'variants.scaled-small',
          'variants.scaled-medium',
          'variants.scaled-large',
          'variants.scaled-xlarge',

          // Cropped variants for listing thumbnail images
          `variants.${variantPrefix}`,
          `variants.${variantPrefix}-2x`,
          `variants.${variantPrefix}-4x`,
          `variants.${variantPrefix}-6x`,

          // Avatars
          'variants.square-small',
          'variants.square-small2x',
        ],

        // create variants
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),

        'limit.images': 1,
      };

      // Query all listings that has
      // currentUser cart listingIds
      return sdk.listings
        .query(params)
        .then(response => {
          dispatch(addMarketplaceEntities(response));
          dispatch(queryListingsSuccess(response));

          return response;
        })
        .catch(e => {
          dispatch(queryListingsError(storableError(e)));
          throw e;
        });
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryGuestListings = () => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  const cartItems =
    typeof window !== 'undefined' && window.localStorage.getItem('cart')
      ? JSON.parse(window.localStorage.getItem('cart'))
      : [];
  const cartItemListingIds = cartItems ? cartItems.map(i => i.listingId) : [];

  // Listing query params
  const params = {
    ids: cartItemListingIds,
    minStock: MINIMAL_LISTING_STOCK,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.listing': ['title', 'price', 'publicData'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],

    // create variants
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),

    'limit.images': 1,
  };

  // Query all listings that has
  // currentUser cart listingIds
  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const addToCurrentUserCart = (listingId, quantity = 1, variants) => (
  dispatch,
  getState,
  sdk
) => {
  if (!listingId) {
    return null;
  }

  dispatch(addToCartRequest());

  // Show the currentUser
  return sdk.currentUser
    .show()
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      const currentUser = entities?.[0];
      const cartItemsFromPublicData = currentUser.attributes.profile.publicData?.cart;

      const variantsMaybe = variants ? { variants } : {};
      const newCartItem = {
        id: uuidv4(),
        listingId,
        quantity,
        ...variantsMaybe,
      };

      let cartItems = [];
      if (cartItemsFromPublicData) {
        cartItems = [...cartItemsFromPublicData, newCartItem];
      } else {
        cartItems = [newCartItem];
      }

      // Update the currentUser
      return sdk.currentUser
        .updateProfile({
          publicData: {
            cart: cartItems,
          },
        })
        .then(() => {
          dispatch(fetchCurrentUserCartListings());
          dispatch(addToCartSuccess());
        })
        .catch(e => dispatch(addToCartError(storableError(e))));
    })
    .catch(e => dispatch(addToCartError(storableError(e))));
};

export const addToGuestCart = (listingId, quantity = 1, variants) => (dispatch, getState, sdk) => {
  if (!listingId) {
    return null;
  }

  dispatch(addToCartRequest());

  const cartItemsFromLocalStorage = window.localStorage.getItem('cart')
    ? JSON.parse(window.localStorage.getItem('cart'))
    : [];
  const variantsMaybe = variants ? { variants } : {};
  const newCartItem = {
    id: uuidv4(),
    listingId,
    quantity,
    ...variantsMaybe,
  };

  let cartItems = [];
  if (cartItemsFromLocalStorage) {
    cartItems = [...cartItemsFromLocalStorage, newCartItem];
  } else {
    cartItems = [newCartItem];
  }

  return setLocalStorageItemAsync('cart', cartItems)
    .then(() => {
      dispatch(fetchGuestUserCartListings(cartItems));
      dispatch(addToCartSuccess());
    })
    .catch(e => dispatch(addToCartError(storableError(e))));
};

export const addToCart = (listingId, quantity = 1, variants) => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  if (currentUser) {
    dispatch(addToCurrentUserCart(listingId, quantity, variants));
  } else {
    dispatch(addToGuestCart(listingId, quantity, variants));
  }
};

export const removeFromCurrentUserCart = listingId => (dispatch, getState, sdk) => {
  dispatch(removeFromCartRequest(listingId));

  const { cartResultIds: currentUserCartFromPageState } = getState().CartPage;
  const { currentUserCart } = getState().user;

  const updatedCurrentUserCartFromPageState = currentUserCartFromPageState.filter(
    id => id.uuid !== listingId
  );
  const updatedCurrentUserCart = currentUserCart?.filter(i => i.listingId !== listingId);

  return sdk.currentUser
    .updateProfile({
      publicData: {
        cart: updatedCurrentUserCart,
      },
    })
    .then(response => {
      dispatch(fetchCurrentUserCartListings());
      dispatch(removeFromCartSuccess(updatedCurrentUserCartFromPageState));
      return response;
    })
    .catch(e => dispatch(removeFromCartError(storableError(e))));
};

export const removeFromGuestCart = listingId => (dispatch, getState, sdk) => {
  dispatch(removeFromCartRequest(listingId));

  const { cartResultIds: currentUserCartFromPageState } = getState().CartPage;
  const { currentUserCart } = getState().user;

  const updatedCurrentUserCartFromPageState = currentUserCartFromPageState.filter(
    id => id.uuid !== listingId
  );
  const updatedCurrentUserCart = currentUserCart?.filter(i => i.listingId !== listingId);

  return setLocalStorageItemAsync('cart', updatedCurrentUserCart)
    .then(() => {
      dispatch(fetchGuestUserCartListings(updatedCurrentUserCart));
      dispatch(removeFromCartSuccess(updatedCurrentUserCartFromPageState));
      return updatedCurrentUserCart;
    })
    .catch(e => dispatch(removeFromCartError(storableError(e))));
};

export const removeFromCart = (listingId, quantity = 1) => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;

  if (currentUser) {
    dispatch(removeFromCurrentUserCart(listingId, quantity));
  } else {
    dispatch(removeFromGuestCart(listingId, quantity));
  }
};

export const updateCurrentUserQuantity = (listingId, newQuantity) => (dispatch, getState, sdk) => {
  dispatch(updateQuantityRequest(listingId, newQuantity));

  const { currentUserCart } = getState().user;

  const updatedCurrentUserCart = currentUserCart.map(i =>
    i.listingId === listingId
      ? {
          ...i,
          quantity: newQuantity,
        }
      : i
  );

  return sdk.currentUser
    .updateProfile({
      publicData: { cart: updatedCurrentUserCart },
    })
    .then(response => {
      dispatch(updateQuantitySuccess());
      dispatch(fetchCurrentUserCartListings());
      return response;
    })
    .catch(e => dispatch(updateQuantityError(storableError(e))));
};

export const updateGuestQuantity = (listingId, newQuantity) => (dispatch, getState, sdk) => {
  dispatch(updateQuantityRequest(listingId, newQuantity));

  const { currentUserCart } = getState().user;

  const updatedCurrentUserCart = currentUserCart.map(i =>
    i.listingId === listingId
      ? {
          ...i,
          quantity: newQuantity,
        }
      : i
  );

  return setLocalStorageItemAsync('cart', updatedCurrentUserCart)
    .then(() => {
      dispatch(updateQuantitySuccess());
      dispatch(fetchGuestUserCartListings(updatedCurrentUserCart));
      return updatedCurrentUserCart;
    })
    .catch(e => dispatch(updateQuantityError(storableError(e))));
};

export const updateQuantity = (listingId, newQuantity) => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;

  if (currentUser) {
    dispatch(updateCurrentUserQuantity(listingId, newQuantity));
  } else {
    dispatch(updateGuestQuantity(listingId, newQuantity));
  }
};

export const loadData = () => (dispatch, getState, sdk) => {
  const { isAuthenticated } = getState().Auth;
  const { currentUser } = getState().user;

  const fetchCurrentUserListings =
    isAuthenticated &&
    currentUser?.attributes?.email !== process.env.REACT_APP_GUEST_CHECKOUT_USERNAME;

  if (fetchCurrentUserListings) {
    return dispatch(queryListings());
  } else {
    return dispatch(queryGuestListings());
  }
};
