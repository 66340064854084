import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';

import { numberAtLeast } from '../../../util/validators';
import { intlShape, injectIntl } from '../../../util/reactIntl';

import { Form, FieldSelect } from '../../../components';
import classNames from 'classnames';

import css from './QuantityForm.module.css';

const QuantityFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        handleSubmit,
        intl,
        formId,
        form,
        values,
        currentStock,
        minimumOrderQuantity
      } = fieldRenderProps;

      const handleOnChange = e => {
        const selectedQuantity = parseInt(e.target.value, 10);
        if (selectedQuantity < minimumOrderQuantity) {
          return;
        }
        form.change('quantity', selectedQuantity);
        handleSubmit(values);
      };

      const quantityRequiredMsg = intl.formatMessage({ id: 'ProductOrderForm.quantityRequired' });

      const hasStock = currentStock && currentStock > 0;
      const quantities = hasStock 
        ? Array.from({ length: currentStock - minimumOrderQuantity + 1 }, (_, i) => parseInt(minimumOrderQuantity, 10) + i) 
        : [];
      
      const hasNoStockLeft = typeof currentStock != null && currentStock === 0;

      const classes = classNames(rootClassName || css.root, className);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {hasNoStockLeft ? null : (
            <FieldSelect
              id={`${formId}.quantity`}
              className={css.quantityField}
              name="quantity"
              disabled={!hasStock}
              validate={numberAtLeast(quantityRequiredMsg, minimumOrderQuantity)}
              onChange={handleOnChange}
            >
              <option disabled value="">
                {intl.formatMessage({ id: 'QuantityForm.selectQuantityOption' })}
              </option>
              {quantities.map(quantity => (
                <option key={quantity} value={quantity}>
                  {intl.formatMessage({ id: 'QuantityForm.quantityOption' }, { quantity })}
                </option>
              ))}
            </FieldSelect>
          )}
        </Form>
      );
    }}
  />
);

QuantityFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
  sendReviewError: null,
  minimumOrderQuantity: 1,
};

const { func, string } = PropTypes;

QuantityFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  minimumOrderQuantity: PropTypes.number,
};

const QuantityForm = compose(injectIntl)(QuantityFormComponent);
QuantityForm.displayName = 'QuantityForm';

export default QuantityForm;
