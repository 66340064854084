import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconShoppingCart.module.css';

const IconShoppingCart = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M23.275 11.491a1.715 1.715 0 00-.991-1.767 1.715 1.715 0 00-.706-.153H3.166a1.714 1.714 0 00-1.634 1.208c-.07.23-.092.473-.063.712l1.286 10.286a1.714 1.714 0 001.714 1.509h15.84a1.714 1.714 0 001.714-1.509l1.252-10.286zM4.658 9.571v-.857a7.714 7.714 0 0115.428 0v.857M8.944 14.714v3.429M15.8 14.714v3.429"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

IconShoppingCart.defaultProps = {
  className: null,
  rootClassName: null,
};

IconShoppingCart.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconShoppingCart;
