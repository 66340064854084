import routeConfiguration from '../routing/routeConfiguration';
import { matchPathname } from '../util/routes';

export const isCurrentRouteGuestCheckout = location => {
  const routes = routeConfiguration();
  const matches = matchPathname(location.pathname, routes);
  return matches.length === 1 && matches[0].route.name === 'GuestCheckoutPage';
};

export const shouldLogoutCurrentUser = (currentUser, isGuestCheckoutRoute) => {
  const isCurrentUserGuest =
    currentUser && currentUser.attributes.email === process.env.REACT_APP_GUEST_CHECKOUT_USERNAME;

  return isCurrentUserGuest && !isGuestCheckoutRoute;
};
