import React from 'react';
import PropTypes from 'prop-types';

const IconWallet = props => {
  const { className } = props;
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <g
        fill="none"
        stroke="#4a4a4a"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="scale(1.71429)"
      >
        <path d="M12 7.5v-2a1 1 0 00-1-1H1.5a1 1 0 00-1 1v7a1 1 0 001 1H11a1 1 0 001-1V10M3.84 2L9.51.52a.49.49 0 01.61.36L10.4 2" />
        <rect x={10} y={7.5} width={3.5} height={2.5} rx={0.5} />
      </g>
    </svg>
  );
};

IconWallet.defaultProps = {
  className: null,
};

const { string } = PropTypes;

IconWallet.propTypes = {
  className: string,
};

export default IconWallet;
