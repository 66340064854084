import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';

import config from '../../../config';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { formatMoney } from '../../../util/currency';
import { numberAtLeast, required } from '../../../util/validators';
import { types as sdkTypes } from '../../../util/sdkLoader';

import {
  Form,
  FieldSelect,
  InlineTextButton,
  PrimaryButton,
  SecondaryButton,
  NamedLink,
  FieldColorButton,
} from '../../../components';

import EstimatedCustomerBreakdownMaybe from '../EstimatedCustomerBreakdownMaybe';

import css from './ProductOrderForm.module.css';
import { TRANSITION_ENQUIRE } from '../../../util/transaction';

const { Money } = sdkTypes;

const renderForm = formRenderProps => {
  const {
    // FormRenderProps from final-form
    handleSubmit,
    form: formApi,
    // Custom props passed to the form component
    intl,
    formId,
    location,
    currentUser,
    currentStock,
    listingId,
    isOwnListing,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    values,
    onAddToCart,
    addToCartInProgress,
    addToCartError,
    isListingAlreadyInCart,
    isAddToCartButtonDisabled,
    shippingFee,
    colorOptions,
    sizeOptions,
    minimumOrderQuantity,
    onUpdateQuantity,
    onChangeColor,
    onChangeSize,
    transaction,
    enquireButton,
  } = formRenderProps;
  const transactionMetadata = transaction?.attributes.metadata || {}
  const { price, shippingFee: transactionShippingFee, description } = transactionMetadata

  const handleOnChange = formValues => {
    const { quantity: quantityRaw, color, size } = formValues.values;
    const quantity = Number.parseInt(quantityRaw, 10);
    const isBrowser = typeof window !== 'undefined';

    if (price && transactionShippingFee && description) {
      onFetchTransactionLineItems({
        orderData: { quantity, shippingFee, useMsrpPrice: !currentUser },
        listingId,
        isOwnListing,
      });
    } else if (isBrowser) {
      if (color) {
        onChangeColor(color);
      }

      const currentSize = sizeOptions?.find(s => s.key === size);
      if (size && currentSize) {
        const currentSize = sizeOptions.find(s => s.key === size);
        onChangeSize(currentSize);
      }
      if (quantity && !fetchLineItemsInProgress) {
        const sizeMaybe = currentSize ? { size: currentSize } : {};

        onUpdateQuantity(quantity);

        if (sizeOptions && !size) {
          return;
        }

        onFetchTransactionLineItems({
          orderData: { quantity, shippingFee, useMsrpPrice: !!(currentUser), ...sizeMaybe },
          listingId,
          isOwnListing,
        });
      }
    }
  };

  // In case quantity and deliveryMethod are missing focus on that select-input.
  // Otherwise continue with the default handleSubmit function.
  const handleFormSubmit = e => {
    const { quantity } = values || {};
    if (!quantity || quantity < 1) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('quantity');
      formApi.focus('quantity');
    } else {
      handleSubmit(e);
    }
  };

  const breakdownData = {};
  const showBreakdown =
    !!currentUser && breakdownData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;
  const breakdown = showBreakdown ? (
    <div className={css.breakdownWrapper}>
      <h3>
        <FormattedMessage id="ProductOrderForm.breakdownTitle" />
      </h3>
      <EstimatedCustomerBreakdownMaybe
        unitType={config.lineItemUnitType}
        breakdownData={breakdownData}
        lineItems={lineItems}
      />
    </div>
  ) : null;

  const showContactUser = typeof onContactUser === 'function';

  const onClickContactUser = e => {
    e.preventDefault();
    onContactUser();
  };

  const contactSellerLink = (
    <InlineTextButton onClick={onClickContactUser}>
      <FormattedMessage id="ProductOrderForm.finePrintNoStockLinkText" />
    </InlineTextButton>
  );
  const quantityRequiredMsg = intl.formatMessage({ id: 'ProductOrderForm.quantityRequired' });

  const selectedColor = colorOptions?.find(c => values.color === c.key);
  const selectedColorLabel = selectedColor?.label;
  const colorLabel = intl.formatMessage(
    {
      id: 'ProductOrderForm.color',
    },
    {
      color: selectedColorLabel,
    }
  );
  const hasColors = colorOptions.length > 0;
  const hasSizes = sizeOptions?.length > 0;

  const hasStock = currentStock && currentStock > 0;
  const quantities = hasStock ? [...Array(currentStock).keys()].map(i => i + 1) : [];
  const hasNoStockLeft = typeof currentStock != null && currentStock === 0;

  const showBuyNow = !!(price && transactionShippingFee && description) // have Offer
    && transaction?.attributes?.lastTransition === TRANSITION_ENQUIRE; // within enquiry phase
  
  const submitInProgress = fetchLineItemsInProgress;
  const submitDisabled = !hasStock;

  const offerBuyNowBtn = showBuyNow ? currentUser ? (
    <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
      {hasStock ? (
        <FormattedMessage id="ProductOrderForm.ctaButton" />
      ) : (
        <FormattedMessage id="ProductOrderForm.ctaButtonNoStock" />
      )}
    </PrimaryButton>
  ) : (
    <NamedLink
      className={css.submitMaskButton}
      name="BuyerSignupPage"
      to={{
        state: {
          from: `${location.pathname}${location.search}${location.hash}`,
        },
      }}
    >
      <FormattedMessage id="ProductOrderForm.ctaButton" />
    </NamedLink>
  ) : null;

  const addToCartBtn = !showBuyNow // hide add to cart: if there is an offer 
    ? currentUser ? (
    <PrimaryButton
      className={css.addToCartButton}
      type="button"
      onClick={onAddToCart}
      inProgress={addToCartInProgress}
      disabled={isAddToCartButtonDisabled}
    >
      {isListingAlreadyInCart ? (
        <FormattedMessage id="ProductOrderForm.alreadyInCart" />
      ) : (
        <FormattedMessage id="ProductOrderForm.addToCart" />
      )}
    </PrimaryButton>
  ) : (
    <NamedLink
      className={css.addToCartMaskButton}
      name="BuyerSignupPage"
      to={{
        state: {
          from: `${location.pathname}${location.search}${location.hash}`,
        },
      }}
    >
      <FormattedMessage id="ProductOrderForm.addToCart" />
    </NamedLink>
    ) : null;

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormSpy subscription={{ values: true }} onChange={handleOnChange} />

      {!(price && transactionShippingFee && description)
        ? (<>{hasColors ? (
          <FieldColorButton
            className={css.colorButtons}
            id={formId ? `${formId}.color` : 'color'}
            name="color"
            label={colorLabel}
            options={colorOptions}
          />
        ) : null}

          {hasSizes ? (
            <FieldSelect
              id={`${formId}.size`}
              className={css.sizeField}
              name="size"
              label={intl.formatMessage({ id: 'ProductOrderForm.sizeLabel' })}
              validate={required(
                intl.formatMessage({
                  id: 'ProductOrderForm.sizeRequired',
                })
              )}
            >
              <option disabled value="">
                {intl.formatMessage({ id: 'ProductOrderForm.sizePlaceholder' })}
              </option>
              {sizeOptions.map(size => {
                const sizeTranslationId = currentUser
                  ? 'ProductOrderForm.sizeOption'
                  : 'ProductOrderForm.msrpSizeOption';
                return (
                  <option key={size.key} value={size.key}>
                    {intl.formatMessage(
                      {
                        id: sizeTranslationId,
                      },
                      {
                        label: size.label,
                        price: formatMoney(intl, new Money(size.price.amount, size.price.currency)),
                      }
                    )}
                  </option>
                );
              })}
            </FieldSelect>
          ) : null}

          {hasNoStockLeft ? null : (
            <FieldSelect
              id={`${formId}.quantity`}
              className={css.quantityField}
              name="quantity"
              disabled={!hasStock}
              label={intl.formatMessage({ id: 'ProductOrderForm.quantityLabel' })}
              validate={numberAtLeast(quantityRequiredMsg, 1)}
            >
              <option disabled value="">
                {intl.formatMessage({ id: 'ProductOrderForm.selectQuantityOption' })}
              </option>
              {quantities.map(quantity => {
                const minimumOrderQuantityAsNumber = parseInt(minimumOrderQuantity);
                const isQuantityDisabled = minimumOrderQuantity
                  ? quantity < minimumOrderQuantityAsNumber
                  : !!minimumOrderQuantity;
                
                return (
                  <option key={quantity} value={quantity} disabled={isQuantityDisabled}>
                    {intl.formatMessage({ id: 'ProductOrderForm.quantityOption' }, { quantity })}
                  </option>
                );
              })}
            </FieldSelect>
          )}</>)
        : null}

      {breakdown}

      <div className={css.submitButton}>
        <p className={css.finePrint}>
          {hasStock ? (
            <FormattedMessage id="ProductOrderForm.finePrint" />
          ) : showContactUser ? (
            <FormattedMessage
              id="ProductOrderForm.finePrintNoStock"
              values={{ contactSellerLink }}
            />
          ) : null}
        </p>
        {offerBuyNowBtn}
        {addToCartBtn}
        {enquireButton}
      </div>
      {addToCartError ? (
        <p className={css.addToCartError}>
          <FormattedMessage id="ProductOrderForm.addToCartError" />
        </p>
      ) : null}
    </Form>
  );
};

const ProductOrderForm = props => {
  const intl = useIntl();
  const { price, currentStock } = props;

  if (!price) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingPriceMissing" />
      </p>
    );
  }
  if (price.currency !== config.currency) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingCurrencyInvalid" />
      </p>
    );
  }
  const hasOneItemLeft = currentStock && currentStock === 1;
  const quantityMaybe = hasOneItemLeft ? { quantity: '1' } : {};

  const initialValues = { ...quantityMaybe };

  return <FinalForm initialValues={initialValues} {...props} intl={intl} render={renderForm} />;
};

ProductOrderForm.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  currentStock: null,
  listingId: null,
  isOwnListing: false,
  lineItems: null,
  fetchLineItemsError: null,

  // cart
  onAddToCart: null,
  addToCartInProgress: false,
  addToCartError: null,
  isListingAlreadyInCart: false,
  isAddToCartButtonDisabled: false,
};

ProductOrderForm.propTypes = {
  rootClassName: string,
  className: string,

  // form
  formId: string.isRequired,
  onSubmit: func.isRequired,

  // listing
  listingId: propTypes.uuid,
  price: propTypes.money,
  currentStock: number,
  isOwnListing: bool,

  // line items
  lineItems: propTypes.lineItems,
  onFetchTransactionLineItems: func.isRequired,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // other
  onContactUser: func,

  // cart
  onAddToCart: func.isRequired,
  addToCartInProgress: bool.isRequired,
  addToCartError: propTypes.error,
  isListingAlreadyInCart: bool.isRequired,
  isAddToCartButtonDisabled: bool.isRequired,
};

export default ProductOrderForm;
