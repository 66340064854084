import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconInbox.module.css';

const IconInbox = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.5.5h-11a1 1 0 00-1 1v11a1 1 0 001 1h11a1 1 0 001-1v-11a1 1 0 00-1-1z"
      ></path>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M.5 8H4a1 1 0 011 1 2 2 0 004 0 1 1 0 011-1h3.5"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

IconInbox.defaultProps = {
  className: null,
  rootClassName: null,
};

IconInbox.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconInbox;
