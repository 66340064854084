import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSearch.module.css';

const IconSearch = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 25 26"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        d="M10.605 20.44a9.291 9.291 0 100-18.583 9.291 9.291 0 000 18.583zM23.6 24.143l-6.43-6.429"
      ></path>
    </svg>
  );
};

IconSearch.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconSearch.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSearch;
