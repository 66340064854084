import React from 'react';
import { bool, array, shape, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { removeFromCart, updateQuantity } from './CartPage.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { createSlug } from '../../util/urlHelpers';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  getUserShippingAddress,
  getShippingFee,
  findListingWithHighestShippingFee,
} from '../../util/data';
import { addQuantityToListing } from '../../util/cartHelpers';
import { login } from '../../ducks/Auth.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  UserNav,
  NamedLink,
  IconError,
  IconCart,
} from '../../components';
import config from '../../config';
import routeConfiguration from '../../routing/routeConfiguration';
import classNames from 'classnames';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import CartDisplayMaybe from './CartDisplayMaybe';
import CartPanel from './CartPanel';

import css from './CartPage.module.css';

const { UUID, Money } = sdkTypes;

const CartPageComponent = props => {
  const {
    intl,
    currentUser,
    currentUserCart,
    scrollingDisabled,
    hasListingsInCart,
    cartListings,
    fetchListingsInProgress,
    fetchListingsError,
    onRemoveFromCart,
    currentRemoveListingIds,
    removeFromCartInProgress,
    removeFromCartError,
    onUpdateQuantity,
  } = props;

  const handleSingleCartPurchase = (firstListingId, otherListings, cartTotalPrice) => {
    const { history, getListing, callSetInitialValues, onInitializeCardPaymentData } = props;

    const listingId = new UUID(firstListingId);
    const listing = getListing(listingId);

    const listingWithQuantity = addQuantityToListing(listing, currentUserCart);
    const mainListingQuantity = listingWithQuantity.quantity;

    const currentUserShippingAddress = getUserShippingAddress(currentUser);

    const listingWithHighestShippingFee = otherListings
      ? findListingWithHighestShippingFee([listing, ...otherListings])
      : listing;
    const shippingFee = getShippingFee(
      listingWithHighestShippingFee?.attributes?.publicData?.shippingFee,
      listingWithHighestShippingFee?.attributes?.publicData?.shippingFeePerCountry,
      currentUser
    );

    const isCartPurchase = !!otherListings;
    const cartPurchaseMaybe = isCartPurchase
      ? {
          cartPurchase: {
            price: cartTotalPrice,
            otherListings,
          },
        }
      : {};

    // Find the first listing from the user's cart
    const firstListingFromCart = currentUserCart.find(c => c.listingId === firstListingId);

    // Extract color and size information from the first listing
    const firstListingColor = firstListingFromCart?.variants?.color;
    const firstListingSize = firstListingFromCart?.variants?.size;

    // Define objects for color and size, if available
    const colorMaybe = firstListingColor ? { color: firstListingColor } : {};
    const sizeMaybe = firstListingSize ? { size: firstListingSize } : {};

    // Set size for variant, if available
    const sizeForVariantMaybe = firstListingSize ? { size: firstListingSize.key } : {};

    // Define variants object based on color and size availability
    const variantsMaybe =
      firstListingColor || firstListingSize
        ? { variants: { ...colorMaybe, ...sizeForVariantMaybe } }
        : {};

    const currentListing = {
      ...listing,
      ...variantsMaybe,
      attributes: {
        ...listing.attributes,

        price: firstListingSize
          ? new Money(firstListingSize.price.amount, firstListingSize.price.currency)
          : listing.attributes.price,
      },
    };

    const initialValues = {
      listing: currentListing,
      orderData: {
        quantity: isCartPurchase ? Number.parseInt(1, 10) : mainListingQuantity,
        mainListingQuantity,
        shippingFee,
        shippingDetails: currentUserShippingAddress,
        ...cartPurchaseMaybe,
        ...colorMaybe,
        ...sizeMaybe,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected orderData
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  };

  // Render errors
  const showEmptyCardMessage = !fetchListingsInProgress && !hasListingsInCart;
  const emptyCartMessage = showEmptyCardMessage ? (
    <div className={css.emptyCartContainer}>
      <IconCart className={css.emptyCartIcon} />
      <p className={css.emptyCartMessage}>
        <FormattedMessage id="CartPage.emptyCart" />
      </p>
    </div>
  ) : null;

  const showFetchListingError =
    !fetchListingsInProgress & fetchListingsError ? (
      <div className={css.errorContainer}>
        <IconError className={css.errorIcon} />
        <p className={classNames(css.errorMessage, css.listingsErrorMessage)}>
          <FormattedMessage id="CartPage.fetchListingError" />
        </p>
      </div>
    ) : null;

  const cartWrapperClasses = classNames(css.cartWrapper, {
    [css.cartWrapperWithListings]: !fetchListingsInProgress && hasListingsInCart,
  });

  const title = intl.formatMessage(
    {
      id: 'CartPage.title',
    },
    { siteTitle: config.siteTitle }
  );

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="CartPage" removeNotice={true} />
          <UserNav selectedPageName="CartPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.main}>
          <div className={css.searchPageLinkContainer}>
            <NamedLink className={css.searchPageLink} name="SearchPage">
              <FormattedMessage id="CartPage.searchPageLink" />
            </NamedLink>
          </div>
          <div className={css.mainPanel}>
            <div className={cartWrapperClasses}>
              {emptyCartMessage}
              {showFetchListingError}
              <CartDisplayMaybe
                intl={intl}
                currentUserCart={currentUserCart}
                cartListings={cartListings}
                hasListingsInCart={hasListingsInCart}
                fetchListingsInProgress={fetchListingsInProgress}
                fetchListingsError={fetchListingsError}
                onRemoveFromCart={onRemoveFromCart}
                currentRemoveListingIds={currentRemoveListingIds}
                removeFromCartInProgress={removeFromCartInProgress}
                removeFromCartError={removeFromCartError}
                handleSingleCartPurchase={handleSingleCartPurchase}
                onUpdateQuantity={onUpdateQuantity}
              />
              <CartPanel intl={intl} />
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

CartPageComponent.defaultProps = {
  cartListings: [],
  fetchListingsError: null,
};

CartPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,

  scrollingDisabled: bool.isRequired,
  hasListingsInCart: bool.isRequired,
  cartListings: array.isRequired,
  fetchListingsInProgress: bool.isRequired,
  fetchListingsError: propTypes.error,
  callSetInitialValues: func.isRequired,
  onUpdateProfile: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    cartResultIds,
    fetchListingsInProgress,
    fetchListingsError,
    currentRemoveListingIds,
    removeFromCartInProgress,
    removeFromCartError,
  } = state.CartPage;
  const { currentUser, currentUserCart } = state.user;
  const { updateInProgress: updateProfileInProgress } = state.ProfileSettingsPage;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const hasListingsInCart = cartResultIds.length > 0;
  const cartListings = getListingsById(state, cartResultIds);

  return {
    getListing,
    scrollingDisabled: isScrollingDisabled(state),
    hasListingsInCart,
    cartListings,
    fetchListingsInProgress,
    fetchListingsError,
    currentRemoveListingIds,
    removeFromCartInProgress,
    removeFromCartError,
    currentUser,
    currentUserCart,
    updateProfileInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onRemoveFromCart: listingId => dispatch(removeFromCart(listingId)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onUpdateProfile: params => dispatch(updateProfile(params)),
  onUpdateQuantity: (listingId, newQuantity) => dispatch(updateQuantity(listingId, newQuantity)),
});

const CartPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(CartPageComponent);

export default CartPage;
