import React from 'react';
import { string } from 'prop-types';
import { ResponsiveImage } from '../../components';
import { findConfigForSelectFilter } from '../../util/search';
import { formatMoney } from '../../util/currency';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';
import classNames from 'classnames';

import css from './CartDetailsContainer.module.css';

const ListingCard = ({ intl, listing }) => {
  const { title, price, publicData } = listing.attributes;
  const listingTitle = title;
  const listingImages = listing.images;

  const hasImages = listingImages.length > 0;
  const firstImage = hasImages ? listingImages[0] : null;

  const customColor = publicData?.customColor || [];
  const colorConfig = findConfigForSelectFilter('color', config.custom.filters);
  const colors = colorConfig && colorConfig.options ? colorConfig.options.concat(customColor) : [];

  const sizeConfig = findConfigForSelectFilter('size', config.custom.filters);
  const sizes = sizeConfig && sizeConfig.options ? sizeConfig.options : [];

  const listingColor = listing.variants?.color;
  const listingSize = listing.variants?.size;

  const currentColor = colors.find(c => c.key === listingColor);
  const currentSize = sizes.find(s => s.key === listingSize);

  // Listing price
  const formattedPrice = price ? formatMoney(intl, price) : null;

  return (
    <div className={css.listingCard}>
      <div className={css.listingImageWrapper}>
        <ResponsiveImage
          rootClassName={css.listingImage}
          alt={listingTitle}
          image={firstImage}
          variants={['scaled-medium']}
        />
      </div>
      <div className={css.listingCardInfo}>
        <div className={css.listingTitle}>{listingTitle}</div>
        {currentColor || currentSize ? (
          <div className={css.variants}>
            {currentColor ? <span className={css.variant}>{currentColor.label}</span> : null}
            {currentColor && currentSize ? (
              <span className={css.variantSeparator}>
                <FormattedMessage id="CartDetailsContainer.variantSeparator" />
              </span>
            ) : null}
            {currentSize ? <span className={css.variant}>{currentSize.label}</span> : null}
          </div>
        ) : null}
        <div className={css.priceContainer}>{formattedPrice}</div>
      </div>
    </div>
  );
};

const CartDetailsContainer = props => {
  const { rootClassName, className, cartPurchase, listing, intl, children } = props;
  const otherListings = cartPurchase?.otherListings;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.listingsPanel}>
        <ListingCard intl={intl} listing={listing} />
        {otherListings?.map(l => {
          return <ListingCard key={l.id.uuid} intl={intl} listing={l} />;
        })}
      </div>
      {children}
    </div>
  );
};

CartDetailsContainer.defaultProps = {
  className: null,
  rootClassName: null,
};

CartDetailsContainer.propTypes = {
  className: string,
  rootClassName: string,
};

export default CartDetailsContainer;
