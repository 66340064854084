import React from 'react';
import { propTypes } from '../../util/types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  IconSocialMediaPinterest,
  IconSocialMediaTiktok,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const {
    siteFacebookPage,
    siteInstagramPage,
    siteTwitterHandle,
    sitePinterestPage,
    siteTiktokPage,
  } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });
  const goToPinterest = intl.formatMessage({ id: 'Footer.goToPinterest' });
  const goToTiktok = intl.formatMessage({ id: 'Footer.goToTiktok' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  const pinterestLink = sitePinterestPage ? (
    <ExternalLink
      key="linkToPinterest"
      href={sitePinterestPage}
      className={css.icon}
      title={goToPinterest}
    >
      <IconSocialMediaPinterest />
    </ExternalLink>
  ) : null;

  const tiktokLink = siteTiktokPage ? (
    <ExternalLink key="linkToTiktok" href={siteTiktokPage} className={css.icon} title={goToTiktok}>
      <IconSocialMediaTiktok />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink, pinterestLink, tiktokLink].filter(v => v != null);
};

const FooterComponent = props => {
  const { rootClassName, className, currentUser, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLiksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <Logo format="desktop" className={css.logo} />
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription" />
                </p>
                <div className={css.someLinks}>{socialMediaLinks}</div>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <span className={css.heading}>
                    <FormattedMessage id="Footer.company" />
                  </span>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="CMSPage" params={{ pageId: 'about' }} className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="CMSPage" params={{ pageId: 'contact' }} className={css.link}>
                    <FormattedMessage id="Footer.toContactPage" />
                  </NamedLink>
                </li>
                {currentUser ? null : (
                  <li className={css.listItem}>
                    <NamedLink name="SellerSignupPage" className={css.link}>
                      <FormattedMessage id="Footer.toSellerSignupPage" />
                    </NamedLink>
                  </li>
                )}
              </ul>
            </div>
            <div className={css.searches}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <span className={css.heading}>
                    <FormattedMessage id="Footer.popularBrands" />
                  </span>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_brand=adored-beast-apothecary',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.link1" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_brand=because-animals',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.link2" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_brand=hugsmarts',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.link3" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_brand=greatpet',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.link4" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.extraLinks}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <span className={css.heading}>
                    <FormattedMessage id="Footer.legal" />
                  </span>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="PrivacyPolicyPage" className={css.link}>
                    <FormattedMessage id="Footer.privacyPolicy" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="TermsOfServicePage" className={css.link}>
                    <FormattedMessage id="Footer.termsOfUse" />
                  </NamedLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={css.copyright}>
          <div className={css.copyrightContent}>
            <span className={css.copyrightText}>
              <FormattedMessage id="Footer.copyright" />
            </span>
            <span className={css.copyrightText}>
              <FormattedMessage
                id="Footer.copyrightOrganization"
                values={{
                  siteTitle: config.siteTitle,
                  heart: <span className={css.heart}>❤</span>,
                }}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

FooterComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
};

FooterComponent.propTypes = {
  rootClassName: string,
  className: string,
  currentUser: propTypes.currentUser,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return { currentUser };
};

const Footer = compose(
  injectIntl,
  connect(mapStateToProps)
)(FooterComponent);

export default Footer;
