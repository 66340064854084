import React from 'react';
import { bool, array, func } from 'prop-types';
import { IconSpinner } from '../../components';

import SellerCart from './SellerCart';
import css from './CartPage.module.css';

// Function that groups the seller ids
// from the listings that are inside users
// shopping cart
const getSellerIds = listings => {
  const sellerIds = listings?.map(l => l.author?.id?.uuid);
  const filteredSellerIds = Array.from(new Set(sellerIds));

  return filteredSellerIds;
};

const CartDisplayMaybe = props => {
  const {
    intl,
    currentUserCart,
    cartListings,
    hasListingsInCart,
    fetchListingsInProgress,
    fetchListingsError,
    onRemoveFromCart,
    removeFromCartInProgress,
    removeFromCartError,
    currentRemoveListingIds,
    handleSingleCartPurchase,
    currentUserHasShippingAddress,
    onOpenShippingModal,
    onUpdateQuantity,
  } = props;

  if (fetchListingsInProgress) {
    return (
      <div className={css.loadingContainer}>
        <IconSpinner className={css.loadingIcon} />
      </div>
    );
  } else if (fetchListingsError) {
    return null;
  }

  // Grouped sellers
  const groupedSellers = getSellerIds(cartListings);

  return hasListingsInCart ? (
    <div className={css.cartDisplay}>
      {groupedSellers?.map(sellerId => {
        const sellerListings = cartListings?.filter(l => l?.author?.id?.uuid === sellerId);
        return (
          <SellerCart
            key={sellerId}
            intl={intl}
            listings={sellerListings}
            currentUserCart={currentUserCart}
            onRemoveFromCart={onRemoveFromCart}
            currentRemoveListingIds={currentRemoveListingIds}
            removeFromCartInProgress={removeFromCartInProgress}
            removeFromCartError={removeFromCartError}
            handleSingleCartPurchase={handleSingleCartPurchase}
            currentUserHasShippingAddress={currentUserHasShippingAddress}
            onOpenShippingModal={onOpenShippingModal}
            onUpdateQuantity={onUpdateQuantity}
          />
        );
      })}
    </div>
  ) : null;
};

CartDisplayMaybe.defaultProps = {
  cartListings: [],
};

CartDisplayMaybe.propTypes = {
  cartListings: array.isRequired,
  hasListingsInCart: bool.isRequired,
  fetchListingsInProgress: bool.isRequired,
  onUpdateQuantity: func.isRequired,
};

export default CartDisplayMaybe;
