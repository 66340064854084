import React from 'react';
import { arrayOf, bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemBasePriceMaybe = props => {
  const {
    lineItems,
    unitType,
    intl,
    listing,
    isCartPurchase,
    otherListings,
    mainListingQuantity,
  } = props;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const translationKey = isNightly
    ? 'OrderBreakdown.baseUnitNight'
    : isDaily
    ? 'OrderBreakdown.baseUnitDay'
    : 'OrderBreakdown.baseUnitQuantity';

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(item => item.code === unitType && !item.reversal);

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  const hasQuantityAndTotal = quantity && total;
  const hasCartPurchaseListings = isCartPurchase && otherListings && hasQuantityAndTotal;

  if (hasCartPurchaseListings) {
    return (
      <div>
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage
              id="OrderBreakdown.itemLabelWithQuantity"
              values={{
                title: listing.attributes.title,
                quantity: mainListingQuantity,
              }}
            />
          </span>
          <span className={css.itemValue}>
            {formatMoney(
              intl,
              new Money(
                listing.attributes.price.amount * mainListingQuantity,
                listing.attributes.price.currency
              )
            )}
          </span>
        </div>
        {otherListings.map(l => {
          const quantity = l.quantity || 1;
          return (
            <div key={l.id.uuid} className={css.lineItem}>
              <span className={css.itemLabel}>
                <FormattedMessage
                  id="OrderBreakdown.itemLabelWithQuantity"
                  values={{
                    title: l.attributes.title,
                    quantity,
                  }}
                />
              </span>
              <span className={css.itemValue}>
                {formatMoney(
                  intl,
                  new Money(l.attributes.price.amount * quantity, l.attributes.price.currency)
                )}
              </span>
            </div>
          );
        })}
      </div>
    );
  }

  return hasQuantityAndTotal ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  unitType: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
  listing: propTypes.listing,
  isCartPurchase: bool,
  otherListings: arrayOf(propTypes.listing),
};

export default LineItemBasePriceMaybe;
