import React from 'react';
import PropTypes from 'prop-types';

const IconMagicWand = props => {
  const { className } = props;
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <g fill="none" stroke="#4a4a4a" strokeLinecap="round" strokeLinejoin="round">
        <path
          d="M21.669 3.206l-1.44 4.251a.703.703 0 000 .634l2.691 3.6a.686.686 0 01-.566 1.098h-4.491a.737.737 0 00-.566.291l-2.503 3.6a.686.686 0 01-1.217-.189l-1.337-4.285a.651.651 0 00-.446-.446L7.51 10.423a.686.686 0 01-.189-1.217l3.669-2.589a.737.737 0 00.291-.566V1.56a.686.686 0 011.029-.566l3.6 2.692a.703.703 0 00.634.085l4.251-1.44a.686.686 0 01.875.875zM12.069 11.931L.857 23.143"
          strokeWidth={1.71429}
        />
      </g>
    </svg>
  );
};

IconMagicWand.defaultProps = {
  className: null,
};

const { string } = PropTypes;

IconMagicWand.propTypes = {
  className: string,
};

export default IconMagicWand;
