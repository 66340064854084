import { integrationSdk } from '../../util/api';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const MARK_ORDER_RECEIVED_REQUEST = 'GuestMarkOrderReceivedPage/MARK_ORDER_RECEIVED_REQUEST';
export const MARK_ORDER_RECEIVED_SUCCESS = 'GuestMarkOrderReceivedPage/MARK_ORDER_RECEIVED_SUCCESS';
export const MARK_ORDER_RECEIVED_ERROR = 'GuestMarkOrderReceivedPage/MARK_ORDER_RECEIVED_ERROR';

// ================ Reducer ================ //

const initialState = {
  orderId: null,
  markOrderReceivedInProgress: false,
  markOrderReceivedError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case MARK_ORDER_RECEIVED_REQUEST:
      return {
        ...state,
        orderId: payload.orderId,
        markOrderReceivedInProgress: true,
        markOrderReceivedError: null,
      };
    case MARK_ORDER_RECEIVED_SUCCESS:
      return {
        ...state,
        markOrderReceivedInProgress: false,
        markOrderReceivedError: null,
      };
    case MARK_ORDER_RECEIVED_ERROR:
      return {
        ...state,
        markOrderReceivedInProgress: false,
        markOrderReceivedError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const markOrderReceivedRequest = orderId => ({
  type: MARK_ORDER_RECEIVED_REQUEST,
  payload: { orderId },
});
export const markOrderReceivedSuccess = () => ({
  type: MARK_ORDER_RECEIVED_SUCCESS,
});
export const markOrderReceivedError = e => ({
  type: MARK_ORDER_RECEIVED_ERROR,
  payload: e,
});

// ================ Selectors ================ //

export const markOrderReceived = (orderId, token) => (dispatch, getState, sdk) => {
  dispatch(markOrderReceivedRequest(orderId));

  return integrationSdk.transactions
    .markReceived({ id: orderId, token })
    .then(response => {
      dispatch(markOrderReceivedSuccess(response));
      return response;
    })
    .catch(e => dispatch(markOrderReceivedError(storableError(e))));
};

export const loadData = params => dispatch => {
  return dispatch(markOrderReceived(params.id, params.token));
};
