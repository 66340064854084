import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';

import css from './FieldColorButton.module.css';

class FieldColorButtonComponent extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.input.onChange(event.target.value);
  }

  render() {
    /* eslint-disable no-unused-vars */
    const {
      rootClassName,
      className,
      inputRootClass,
      customErrorText,
      id,
      intl,
      label,
      input,
      meta,
      options,
      ...rest
    } = this.props;
    /* eslint-enable no-unused-vars */

    const { touched, error } = meta;
    const errorText = customErrorText || error;
    const fieldMeta = { touched, error: errorText };

    const { value, ...restInputProps } = input;
    const inputProps = { ...restInputProps, type: 'radio', name: 'color', ...rest };

    const classes = classNames(rootClassName || css.root, className);

    const createColorButtons = colorOptions => {
      let inputsAndLabels = [];

      for (let [key, color] of colorOptions.entries()) {
        const inputValue = `${color.key}`;
        const colorId = `color${color.label}`;
        const inputId = `${id}.${colorId}`;

        inputsAndLabels.push(
          <input
            key={inputId}
            id={inputId}
            className={css.colorInput}
            value={inputValue}
            checked={color.key === inputValue}
            {...inputProps}
          />
        );

        inputsAndLabels.push(
          <label
            key={`label.${inputId}`}
            className={css.label}
            htmlFor={inputId}
            title={color.label}
          >
            <span
              className={classNames(css.colorBox, {
                [css.colorBoxSelected]: value === color.key,
              })}
              style={{
                color: color.color,
              }}
              data-color={color.color}
            />
          </label>
        );
      }
      return inputsAndLabels;
    };

    return (
      <div className={classes}>
        <fieldset
          className={css.colorFieldSet}
          ref={c => {
            this.colorFieldSet = c;
          }}
        >
          {label ? <legend>{label}</legend> : null}
          <div className={css.color}>{createColorButtons(options)}</div>
        </fieldset>
        <ValidationError fieldMeta={fieldMeta} />
      </div>
    );
  }
}

FieldColorButtonComponent.defaultProps = {
  rootClassName: null,
  className: null,
  customErrorText: null,
  label: null,
};

const { string, shape, func, object } = PropTypes;

FieldColorButtonComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: string,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
  }).isRequired,
  meta: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const FieldColorButton = props => {
  return <Field component={FieldColorButtonComponent} {...props} />;
};

export default injectIntl(FieldColorButton);
