import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconArrow.module.css';

const IconArrow = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="24"
      fill="none"
      viewBox="0 0 20 24"
    >
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        clipPath="url(#clip0_14_100)"
      >
        <path d="M.715 12h18.572M14.286 18l5-6-5-6"></path>
      </g>
      <defs>
        <clipPath id="clip0_14_100">
          <path fill="#fff" d="M0 0H20V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

IconArrow.defaultProps = {
  className: null,
  rootClassName: null,
};

IconArrow.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconArrow;
