import React, { useState } from 'react';
import config from '../../../../config';

import { generateTopbarConfig } from '../../TopbarDesktop/TopbarDropdownMenu/TopbarDropdownMenu.helpers';
import MenuAccordionItem from './MenuAccordionItem/MenuAccordionItem';

const MenuAccordion = () => {
  // Initialize state variables for the active item and sub-item keys
  const [activeItemKey, setActiveItemKey] = useState(null);
  const [activeSubItemKey, setActiveSubItemKey] = useState(null);

  const topbarMenuConfig = generateTopbarConfig(config.custom.filters);

  // Map over the menu options and render each as a 'MenuAccordionItem' component
  return topbarMenuConfig.map(item => (
    <MenuAccordionItem
      key={item.key}
      item={item}
      activeItemKey={activeItemKey}
      setActiveItemKey={setActiveItemKey}
      activeSubItemKey={activeSubItemKey}
      setActiveSubItemKey={setActiveSubItemKey}
    />
  ));
};

export default MenuAccordion;
