import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconAccount.module.css';

const IconAccount = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        d="M11.882 12.143a5.571 5.571 0 100-11.143 5.571 5.571 0 000 11.143zM22.476 23.286a11.126 11.126 0 00-21.188 0h21.188z"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

IconAccount.defaultProps = {
  className: null,
  rootClassName: null,
};

IconAccount.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconAccount;
