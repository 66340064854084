import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { propTypes } from '../../util/types';
import { isUserSeller } from '../../util/data';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';

const UserNavComponent = props => {
  const { className, rootClassName, currentUser, selectedPageName } = props;
  const classes = classNames(rootClassName || css.root, className);

  const isSeller = isUserSeller(currentUser);
  const tabs = isSeller
    ? [
        {
          text: <FormattedMessage id="ManageListingsPage.yourListings" />,
          selected: selectedPageName === 'ManageListingsPage',
          linkProps: {
            name: 'ManageListingsPage',
          },
        },
        {
          text: <FormattedMessage id="ManageListingsPage.profileSettings" />,
          selected: selectedPageName === 'ProfileSettingsPage',
          disabled: false,
          linkProps: {
            name: 'ProfileSettingsPage',
          },
        },
        {
          text: <FormattedMessage id="ManageListingsPage.accountSettings" />,
          selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
          disabled: false,
          linkProps: {
            name: 'ContactDetailsPage',
          },
        },
      ]
    : [
        {
          text: <FormattedMessage id="ManageListingsPage.profileSettings" />,
          selected: selectedPageName === 'ProfileSettingsPage',
          disabled: false,
          linkProps: {
            name: 'ProfileSettingsPage',
          },
        },
        {
          text: <FormattedMessage id="ManageListingsPage.accountSettings" />,
          selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
          disabled: false,
          linkProps: {
            name: 'ContactDetailsPage',
          },
        },
      ];

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
};

const { string } = PropTypes;

UserNavComponent.propTypes = {
  className: string,
  rootClassName: string,
  currentUser: propTypes.currentUser,
  selectedPageName: string.isRequired,
};

const mapStateToProps = state => {
  return {
    currentUser: state.user.currentUser,
  };
};

const UserNav = compose(connect(mapStateToProps))(UserNavComponent);

export default UserNav;
