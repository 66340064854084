// Component that shows animated keyword search.
// This component is currently only being used in TopbarDesktop.
import React, { useRef } from 'react';
import { func, bool, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { Form, FieldTextInput, IconSearch } from '../..';
import { useOutsideAlerter } from '../../../util/hooks';
import classNames from 'classnames';

import css from './TopbarDesktopKeywordForm.module.css';

const SearchIconWithWrapper = props => {
  const { isSearchVisible, setSearchVisible } = props;

  // collapse & expand the search input onClick
  const handleSearchVisibility = () => setSearchVisible(!isSearchVisible);
  return (
    <div className={css.icon} onClick={handleSearchVisibility}>
      <IconSearch />
    </div>
  );
};

SearchIconWithWrapper.defaultProps = {
  isSearchVisible: false,
  setSearchVisible: null,
};

SearchIconWithWrapper.propTypes = {
  isSearchVisible: bool.isRequired,
  setSearchVisible: func.isRequired,
};

const TopbarDesktopKeywordFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        intl,
        handleSubmit,
        isSearchVisible,
        setSearchVisible,
      } = formRenderProps;

      const wrapperRef = useRef(null);
      useOutsideAlerter(wrapperRef, () => setSearchVisible(false));

      const keywordPlaceholder = intl.formatMessage({
        id: 'TopbarDesktopKeywordForm.keywordPlaceholder',
      });

      const classes = classNames(rootClassName || css.root, className, {
        [css.rootIsOpen]: isSearchVisible,
      });
      const inputClasses = classNames(css.input, {
        [css.inputIsOpen]: isSearchVisible,
      });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.inputWrapper} ref={wrapperRef}>
            <FieldTextInput
              className={inputClasses}
              id="keywords"
              name="keywords"
              type="text"
              placeholder={keywordPlaceholder}
            />
            <SearchIconWithWrapper
              isSearchVisible={isSearchVisible}
              setSearchVisible={setSearchVisible}
            />
          </div>
        </Form>
      );
    }}
  />
);

TopbarDesktopKeywordFormComponent.defaultProps = {
  rootClassName: null,
  className: null,

  // from useState
  isSearchVisible: false,
  setSearchVisible: null,
};

TopbarDesktopKeywordFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,

  // from useState
  isSearchVisible: bool.isRequired,
  setSearchVisible: func.isRequired,
};

const TopbarDesktopKeywordForm = TopbarDesktopKeywordFormComponent;

export default TopbarDesktopKeywordForm;
