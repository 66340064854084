import React, { useState, useEffect } from 'react';
import { bool, func, string, number, array } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { isUserSeller } from '../../../util/data';
import { propTypes } from '../../../util/types';
import {
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  Avatar,
  InlineTextButton,
  IconAccount,
  IconInbox,
  IconShoppingCart,
} from '../../../components';

import TopbarDropdownMenu from './TopbarDropdownMenu/TopbarDropdownMenu';
import TopbarDesktopKeywordForm from '../TopbarDesktopKeywordForm/TopbarDesktopKeywordForm';
import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    rootClassName,
    currentUserCart,
    currentUser,
    currentPage,
    notificationCount,
    intl,
    isAuthenticated,
    onSearchSubmit,
    onLogout,
  } = props;

  const [mounted, setMounted] = useState(false);
  const [isSearchVisible, setSearchVisible] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const userDisplayName = currentUser?.attributes?.profile?.displayName;

  const userSeller = isUserSeller(currentUser);

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <div className={css.avatarContainer}>
          <span className={css.userDisplayName}>
            <FormattedMessage id="TopbarDesktop.userDisplayName" values={{ userDisplayName }} />
          </span>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        </div>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage" isHidden={!userSeller}>
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="BulkUploadPage" isHidden={!userSeller}>
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('BulkUploadPage'))}
            name="BulkUploadPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.bulkUploadLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const search = (
    <div
      className={classNames(css.searchLink, {
        [css.searchLinkVisible]: isSearchVisible,
      })}
    >
      <TopbarDesktopKeywordForm
        isSearchVisible={isSearchVisible}
        setSearchVisible={setSearchVisible}
        onSubmit={onSearchSubmit}
        intl={intl}
      />
    </div>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.navigationLink}>
      <span className={css.navigationLinkWrapper}>
        <IconAccount className={css.navigationLinkIcon} />
      </span>
    </NamedLink>
  );

  const showCreateListingLink = false;
  const createListingLink = showCreateListingLink ? (
    <NamedLink className={css.createListingLink} name="NewListingPage">
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.createListing" />
      </span>
    </NamedLink>
  ) : null;

  const inboxLink = (
    <NamedLink
      name="InboxPage"
      className={css.navigationLink}
      params={{ tab: userSeller ? 'sales' : 'orders' }}
    >
      <span className={css.navigationLinkWrapper}>
        {notificationDot}
        <IconInbox className={classNames(css.navigationLinkIcon, css.inboxIcon)} />
      </span>
    </NamedLink>
  );

  const cartListingsCount = currentUserCart?.length;
  const hasListingsInCart = cartListingsCount > 0;
  const shoppingCartLink = (
    <NamedLink name={currentUser ? 'CartPage' : 'BuyerSignupPage'} className={css.navigationLink}>
      {hasListingsInCart ? <span className={css.cartCount}>{cartListingsCount}</span> : null}
      <span className={css.navigationLinkWrapper}>
        <IconShoppingCart className={css.navigationLinkIcon} />
      </span>
    </NamedLink>
  );

  return (
    <div className={css.main}>
      <nav className={classes}>
        <NamedLink className={css.logoLink} name="LandingPage">
          <Logo
            format="desktop"
            className={css.logo}
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
          />
        </NamedLink>
        <div className={css.navigationLinks}>
          {createListingLink}
          {search}
          {inboxLink}
          {shoppingCartLink}
          {profileMenu}
          {loginLink}
        </div>
      </nav>
      <TopbarDropdownMenu />
    </div>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentUserHasListings: false,
  currentPage: null,
  notificationCount: 0,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserCart: array.isRequired,
  currentPage: string,
  notificationCount: number.isRequired,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  onSearchSubmit: func.isRequired,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
