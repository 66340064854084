import React from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { propTypes } from '../../util/types';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedRedirect,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import css from './GuestMarkOrderReceivedPage.module.css';

const LoadingErrorContent = ({ title, message }) => (
  <div className={css.root}>
    <div className={css.content}>
      <h1 className={css.title}>
        <FormattedMessage id={title} />
      </h1>
      {message ? <p className={css.text}>{message}</p> : null}
    </div>
  </div>
);

const GuestMarkOrderReceivedPageComponent = props => {
  const {
    intl,
    scrollingDisabled,
    params,
    markOrderReceivedInProgress,
    markOrderReceivedError,
  } = props;

  if (!params.id || !params.token) {
    return <NamedRedirect name="NotFoundPage" />;
  }

  if (markOrderReceivedInProgress) {
    const loadingPageTitle = intl.formatMessage(
      { id: 'GuestMarkOrderReceivedPage.loadingPageTitle' },
      { orderId: params.id }
    );

    return (
      <Page title={loadingPageTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutWrapperMain}>
            <LoadingErrorContent title="GuestMarkOrderReceivedPage.loadingTitle" />
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  } else if (markOrderReceivedError) {
    const errorPageTitle = intl.formatMessage({
      id: 'GuestMarkOrderReceivedPage.errorPageTitle',
    });

    return (
      <Page title={errorPageTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutWrapperMain}>
            <LoadingErrorContent
              title="GuestMarkOrderReceivedPage.errorTitle"
              message={markOrderReceivedError.message}
            />
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }

  const pageTitle = intl.formatMessage(
    { id: 'GuestMarkOrderReceivedPage.pageTitle' },
    { orderId: params.id }
  );

  return (
    <Page title={pageTitle} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.root}>
            <div className={css.content}>
              <h1 className={css.title}>
                <FormattedMessage id="GuestMarkOrderReceivedPage.title" />
              </h1>
              <p className={css.text}>
                <FormattedMessage
                  id="GuestMarkOrderReceivedPage.text"
                  values={{
                    contactEmail: <strong>{process.env.REACT_APP_CONTACT_EMAIL}</strong>,
                  }}
                />
              </p>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

GuestMarkOrderReceivedPageComponent.defaultProps = {
  markOrderReceivedInProgress: false,
  markOrderReceivedError: null,
};

GuestMarkOrderReceivedPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  markOrderReceivedInProgress: bool.isRequired,
  markOrderReceivedError: propTypes.error,

  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { markOrderReceivedInProgress, markOrderReceivedError } = state.GuestMarkOrderReceivedPage;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    markOrderReceivedInProgress,
    markOrderReceivedError,
  };
};

const GuestMarkOrderReceivedPage = compose(
  connect(mapStateToProps),
  injectIntl
)(GuestMarkOrderReceivedPageComponent);

export default GuestMarkOrderReceivedPage;
