import React, { useState } from 'react';
import { any, string, shape, array, object, bool, func, arrayOf } from 'prop-types';
import { NamedLink, ExternalLink } from '../../..';
import config from '../../../../config';
import classNames from 'classnames';

// helpers
import { generateTopbarConfig, getItemIcon } from './TopbarDropdownMenu.helpers';

// shared
import Dropdown from './Dropdown/Dropdown';
import css from './TopbarDropdownMenu.module.css';

const BRAND_KEY = 'brand';

const MenuLinkItem = props => {
  const { linkProps, children } = props;

  if (linkProps.name) {
    return (
      <NamedLink className={css.navItemLink} {...linkProps}>
        {children}
      </NamedLink>
    );
  }

  return (
    <ExternalLink className={css.navItemLink} {...linkProps}>
      {children}
    </ExternalLink>
  );
};

MenuLinkItem.defaultProps = {
  linkProps: null,
  children: null,
};

MenuLinkItem.propTypes = {
  linkProps: object.isRequired,
  children: any.isRequired,
};

/**
 * Component for showing the single menu list item.
 */
const MenuListItem = props => {
  const { option, isHovered, activeMenuKey, onMouseEnter, onMouseLeave } = props;

  // Destructure option props
  const { label, linkProps, options } = option;

  // Check if current menu is active
  const isCurrentMenuActive = activeMenuKey === option.key;
  const isBrand = option.key === BRAND_KEY;

  // Check if dropdown should be shown
  const showDropdown = isHovered && isCurrentMenuActive && options;
  const dropdown = showDropdown ? (
    <Dropdown
      option={option}
      options={options}
      isCurrentMenuActive={isCurrentMenuActive}
      isBrand={isBrand}
    />
  ) : null;

  // Get item icon
  const itemIcon = getItemIcon(option.key);

  // Define props for item
  const itemProps = {
    onMouseEnter,
    onMouseLeave,
  };

  const classes = classNames(css.navItem, {
    [css.navItemActive]: isBrand ? isCurrentMenuActive : !showDropdown && isCurrentMenuActive,
    [css.navItemWithDropdownActive]: !isBrand && showDropdown,
    [css.brandNavItemActive]: isBrand && isCurrentMenuActive,
  });

  return (
    <li className={classes} {...itemProps}>
      <MenuLinkItem linkProps={linkProps}>
        {itemIcon}
        <span className={css.navItemLabel}>{label}</span>
      </MenuLinkItem>
      {dropdown}
    </li>
  );
};

MenuListItem.defaultProps = {
  option: null,
  isHovered: false,
  activeMenuKey: null,

  // func
  onMouseEnter: null,
  onMouseLeave: null,
};

MenuListItem.propTypes = {
  option: shape({
    key: string.isRequired,
    label: string.isRequired,
    linkProps: object,
  }),
  isHovered: bool.isRequired,
  activeMenuKey: string,

  // func
  onMouseEnter: func.isRequired,
  onMouseLeave: func.isRequired,
};

/**
 * Component for showing the main menu list.
 */
const MenuList = props => {
  const { options } = props;

  // Keep the menu state
  const [isHovered, setIsHovered] = useState(false);
  const [activeMenuKey, setActiveMenuKey] = useState(null);

  // Handle mouse events for menu items
  const onMouseEnter = key => {
    setIsHovered(true);
    setActiveMenuKey(key);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
    setActiveMenuKey(null);
  };

  return (
    <ul className={css.nav}>
      {options.map(o => {
        return (
          <MenuListItem
            key={o.key}
            option={o}
            isHovered={isHovered}
            activeMenuKey={activeMenuKey}
            onMouseEnter={() => onMouseEnter(o.key)}
            onMouseLeave={onMouseLeave}
          />
        );
      })}
    </ul>
  );
};

MenuList.defaultProps = {
  options: [],
};

MenuList.defaultProps = {
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

const TopbarDropdownMenu = props => {
  const { rootClassName, className, filterConfig } = props;
  const classes = classNames(rootClassName || className, css.root);

  // Generate the menu options
  const topbarMenuConfig = generateTopbarConfig(filterConfig);
  return (
    <div className={classes}>
      <div className={css.content}>
        <MenuList options={topbarMenuConfig} />
      </div>
    </div>
  );
};

TopbarDropdownMenu.defaultProps = {
  rootClassName: null,
  className: null,

  // config
  filterConfig: config.custom.filters,
};

TopbarDropdownMenu.propTypes = {
  rootClassName: string,
  className: string,

  // config
  filterConfig: array.isRequired,
};

export default TopbarDropdownMenu;
