import React from 'react';
import classNames from 'classnames';
import { findOptionsForSelectFilter } from '../../../../util/search';

import {
  HomeIcon,
  NewInIcon,
  DogIcon,
  CatIcon,
  BrandIcon,
  SaleIcon,
  ServicesIcon,
} from './topbarMenuIcons';
import css from './TopbarDropdownMenu.module.css';

// Define constants for dropdown item keys and arrow position
export const VIEW_ALL_KEY = 'view-all';
export const NEW_ARRIVAL_KEY = 'new-arrival';

export const HOME = 'home';
export const NEW_IN = 'new-in';
export const DOG = 'dog'; // generated in `generateTopbarConfig` dynamically from `marketplace-custom-config.js`
export const CAT = 'cat'; // generated in `generateTopbarConfig` dynamically from `marketplace-custom-config.js`
export const BRAND = 'brand';
export const SALE = 'sale';
export const HELP_CENTRE = 'help-centre';

/**
 * Check if a given dropdown item is a conflicting item (i.e. has a specific search query).
 * @param {string} key - The key of the dropdown item to check.
 * @returns {boolean} - True if the item is a conflicting item, false otherwise.
 */
export const isConflictingItems = key => key === VIEW_ALL_KEY || key === NEW_ARRIVAL_KEY;

// Get the icon for the given key
export const getItemIcon = key => {
  let icon = null;

  if (key === HOME) {
    icon = <HomeIcon className={classNames(css.navItemIcon, css.homeIcon)} />;
  } else if (key === NEW_IN) {
    icon = <NewInIcon className={css.navItemIcon} />;
  } else if (key === DOG) {
    return <DogIcon className={css.navItemIcon} />;
  } else if (key === CAT) {
    return <CatIcon className={css.navItemIcon} />;
  } else if (key === BRAND) {
    return <BrandIcon className={css.navItemIcon} />;
  } else if (key === SALE) {
    return <SaleIcon className={css.navItemIcon} />;
  } else if (key === HELP_CENTRE) {
    return <ServicesIcon className={css.navItemIcon} />;
  }

  return icon;
};

/**
 * Generate the topbar config based on the given filters.
 */
export const generateTopbarConfig = filters => {
  const categoryFilter = filters.find(f => f.id === 'category');
  const categoryOptions = categoryFilter.config.options;

  const subcategoryFilter = filters.find(f => f.id === 'subcategory');
  const subcategoryOptions = subcategoryFilter.config.options;

  const collectionFilter = filters.find(f => f.id === 'collection');
  const collectionOptions = collectionFilter.config.options;

  const categoryBasedOptions = categoryOptions.map(c => {
    // Find matching subcategories
    const subcategories = subcategoryOptions.filter(
      s => s.category === c.key || s.category === 'all'
    );

    // Loop through collections & match them with subcategories
    const collections = collectionOptions.map(collection => {
      // Find the matching subcategories and inject the main category
      const subcategoriesByCollection = subcategories
        .filter(s => s.collection === collection.key)
        .map(s => ({ ...s, category: c.key }));
      
      return {
        ...collection,
        category: c.key,
        options: [
          { key: 'view-all', label: 'View all', category: c.key, collection: collection.key },
          ...subcategoriesByCollection,
        ],
      };
    });

    const categorySubCategories = subcategories
      .filter(s => s.category === c.key)
      .map(s => {
        const nestedSubCategories = s?.subCategories?.map(subCat => ({
          ...subCat,
          category: c.key,
        }));
      
        return {
          ...s,
          category: c.key,
          options: nestedSubCategories,
        };
      });

    return {
      ...c,
      options: [
        { key: 'view-all', label: 'View all', category: c.key },
        { key: 'new-arrival', label: 'New arrival', category: c.key },
        ...categorySubCategories,
        ...collections,
      ],
      linkProps: {
        name: 'SearchPage',
        to: { search: `pub_category=${c.key}` },
      },
    };
  });

  const brandOptions = findOptionsForSelectFilter('brand', filters);

  return [
    {
      key: HOME,
      label: 'Home',
      linkProps: {
        name: 'LandingPage',
      },
    },
    {
      key: NEW_IN,
      label: 'New in',
      linkProps: {
        name: 'SearchPage',
      },
    },
    ...categoryBasedOptions,
    {
      key: BRAND,
      label: 'Brands',
      options: brandOptions,
      linkProps: {
        name: 'SearchPage',
      },
    },
    {
      key: SALE,
      label: 'Sale',
      linkProps: {
        name: 'SearchPage',
        to: { search: 'pub_sale=true' },
      },
    },
    {
      key: HELP_CENTRE,
      label: 'Help centre',
      linkProps: {
        href: 'https://petspace.uk/help-centre/',
      },
    },
  ];
};
