import React from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedRedirect,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import css from './GuestOrderConfirmationPage.module.css';

const GuestOrderConfirmationPageComponent = props => {
  const { intl, scrollingDisabled, params } = props;

  const title = intl.formatMessage({
    id: 'GuestOrderConfirmationPage.pageTitle',
  });

  if (!params.id) {
    return <NamedRedirect name="NotFoundPage" />;
  }
  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.root}>
            <div className={css.content}>
              <h1 className={css.title}>
                <FormattedMessage id="GuestOrderConfirmationPage.title" />
              </h1>
              <p>
                <FormattedMessage id="GuestOrderConfirmationPage.textLine1" />
              </p>
              <p className={css.textWithBottomMargin}>
                <FormattedMessage id="GuestOrderConfirmationPage.textLine2" />
              </p>
              <p className={css.textWithoutMargin}>
                <FormattedMessage
                  id="GuestOrderConfirmationPage.textLine3"
                  values={{
                    id: <strong>{params.id}</strong>,
                    email: <strong>{process.env.REACT_APP_CONTACT_EMAIL}</strong>,
                  }}
                />
              </p>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

GuestOrderConfirmationPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const GuestOrderConfirmationPage = compose(
  connect(mapStateToProps),
  injectIntl
)(GuestOrderConfirmationPageComponent);

export default GuestOrderConfirmationPage;
