import { useEffect } from 'react';

/**
 * Hook that alerts if the click is outside of the current
 * element that's passed through ref.
 *
 * @param {node} ref
 * @param {func} callbackFn
 *
 */
export const useOutsideAlerter = (ref, callbackFn) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callbackFn();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};
