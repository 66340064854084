export const generateDot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 8,
    width: 8,
  },
});

export const colorStyles = {
  option: (styles, { data, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isFocused || isSelected ? '#7fc9a2' : 'transparent',
    color: isFocused || isSelected ? 'white' : '#4a4a4a',
    ...generateDot(data.color),
  }),
  singleValue: (styles, { data }) => ({ ...styles, ...generateDot(data.color) }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: '#e7e7e7',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    ...generateDot(data.color),
    padding: '0 4px',
  }),
};
