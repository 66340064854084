import React from 'react';
import PropTypes from 'prop-types';

const IconError = props => {
  const { className } = props;
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" height="48" width="48">
      <g
        fill="none"
        stroke="#4a4a4a"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="scale(3.42857)"
      >
        <path d="M11 9l2-.5.5 2" />
        <path d="M13 8.5A6.6 6.6 0 017 13h0a6 6 0 01-5.64-3.95M3 5l-2 .5-.5-2" />
        <path d="M1 5.5A6.79 6.79 0 017 1h0a6 6 0 015.64 4M7 3.5v4" />
        <circle cx={7} cy={10} r={0.5} />
      </g>
    </svg>
  );
};

IconError.defaultProps = {
  className: null,
};

const { string } = PropTypes;

IconError.propTypes = {
  className: string,
};

export default IconError;
