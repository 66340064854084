import React, { useState, useEffect } from 'react';
import { arrayOf, func, bool, number } from 'prop-types';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  getFirstWordFromString,
  sumCartTotal,
  validateOtherListings,
  calculateListingPrice,
} from '../../util/cartHelpers';
import { createSlug, denormaliseSlug } from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import {
  Avatar,
  NamedLink,
  ResponsiveImage,
  Button,
  IconRemove,
  IconSpinner,
} from '../../components';
import QuantityForm from './QuantityForm/QuantityForm';
import css from './CartPage.module.css';

const { Money } = sdkTypes;

const ListingCard = props => {
  const {
    intl,
    listing,
    quantity,
    variants,
    onRemoveFromCart,
    onUpdateQuantity,
    currentRemoveListingIds,
    removeFromCartInProgress,
    removeFromCartError,
  } = props;
  const {
    attributes: { title, price: listingPrice },
    images,
    id,
    currentStock,
  } = listing;
  const price = variants?.size?.price || listing?.attributes?.publicData?.msrpPrice || listingPrice;
  const isBeingRemoved = currentRemoveListingIds.includes(id.uuid);
  const hasImages = images?.length > 0;
  const listingImage = hasImages ? images[0] : null;
  const formattedPrice = formatMoney(intl, new Money(price.amount, price.currency));
  const showLoadingDiv = isBeingRemoved && removeFromCartInProgress;

  return (
    <div className={css.listingCard}>
      {showLoadingDiv && (
        <div className={css.listingCardLoading}>
          <div className={css.listingCardLoadingIcon}>
            <IconSpinner />
          </div>
        </div>
      )}
      <div className={css.listingImageWrapper}>
        <ResponsiveImage
          rootClassName={css.listingImage}
          alt={title}
          image={listingImage}
          variants={['scaled-medium']}
        />
      </div>
      <div className={css.listingInfo}>
        <div>
          <NamedLink
            className={css.listingLink}
            name="ListingPage"
            params={{ id: id.uuid, slug: createSlug(title) }}
          >
            {title}
          </NamedLink>
          {variants?.color || variants?.size ? (
            <div className={css.variants}>
              {variants.color ? (
                <span className={css.variant}>{denormaliseSlug(variants.color)}</span>
              ) : null}
              {variants.color && variants.size ? (
                <span className={css.variantSeparator}>
                  <FormattedMessage id="CartPage.variantSeparator" />
                </span>
              ) : null}
              {variants.size ? <span className={css.variant}>{variants.size.label}</span> : null}
            </div>
          ) : null}
          <div className={css.priceContainer}>
            <FormattedMessage
              id="CartPage.singleListingPrice"
              values={{
                price: formattedPrice,
                quantity,
              }}
            />
          </div>
        </div>
        <div className={css.bottomContainer}>
          {!removeFromCartError && (
            <div className={css.removeIconContainer} onClick={() => onRemoveFromCart(id.uuid)}>
              <IconRemove className={css.removeIcon} />
              <FormattedMessage id="CartPage.removeListing" />
            </div>
          )}
          <QuantityForm
            minimumOrderQuantity={Number(listing?.attributes?.publicData?.minimumOrderQuantity|| 1)}
            onSubmit={values => onUpdateQuantity(id.uuid, Number(values.quantity))}
            currentStock={currentStock?.attributes?.quantity}
            initialValues={{ quantity }}
          />
        </div>
        {isBeingRemoved && removeFromCartError && (
          <p className={css.removeListingError}>
            <FormattedMessage id="CartPage.removeListingError" />
          </p>
        )}
      </div>
    </div>
  );
};

ListingCard.propTypes = {
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  quantity: number.isRequired,
  onRemoveFromCart: func.isRequired,
  onUpdateQuantity: func.isRequired,
  currentRemoveListingIds: arrayOf(number).isRequired,
  removeFromCartInProgress: bool.isRequired,
  removeFromCartError: propTypes.error,
};

const SellerCart = props => {
  const {
    intl,
    listings,
    currentUserCart,
    onRemoveFromCart,
    currentRemoveListingIds,
    removeFromCartInProgress,
    removeFromCartError,
    handleSingleCartPurchase,
    onUpdateQuantity,
  } = props;
  const [cartListings, setCartListings] = useState(currentUserCart || []);

  useEffect(() => {
    setCartListings(currentUserCart);
  }, [currentUserCart]);

  const handleUpdateQuantity = (listingId, newQuantity) => {
    setCartListings(prevListings =>
      prevListings.map(cartListing =>
        cartListing.listingId === listingId
          ? { ...cartListing, quantity: newQuantity }
          : cartListing
      )
    );
    onUpdateQuantity(listingId, newQuantity);
  };

  const firstListingId = listings[0]?.id?.uuid;
  const otherListings = validateOtherListings(firstListingId, listings, cartListings);
  const currentSeller = listings[0]?.author;

  const cartTotalPrice = sumCartTotal(
    listings.map(listing => {
      const listingInCart = cartListings?.find(
        cartListing => cartListing.listingId === listing.id.uuid
      );

      // const price = listingInCart?.variants?.size
      //   ? listingInCart.variants.size.price
      //   : listing.attributes.price;

      const price = listing.attributes?.publicData?.msrpPrice || listing.attributes.price;
      
      const listingMinimumQty = listing.attributes?.publicData?.minimumOrderQuantity || 1
      const quantity = listingInCart?.quantity || listingMinimumQty;

      return {
        ...listing,
        attributes: {
          ...listing.attributes,
          price: calculateListingPrice(price, quantity),
        },
      };
    })
  );

  const formattedCartTotalPrice = formatMoney(intl, cartTotalPrice);
  const sellerDisplayName = currentSeller?.attributes?.profile?.displayName;
  const sellerDisplayFirstName = getFirstWordFromString(sellerDisplayName);

  return (
    <div className={css.cartCard}>
      <div className={css.cartHeader}>
        <div className={css.sellerInfo}>
          <Avatar user={currentSeller} />
          <span className={css.sellerDisplayName}>
            <FormattedMessage
              id="CartPage.sellerDisplayName"
              values={{ displayName: sellerDisplayName }}
            />
          </span>
        </div>
        <NamedLink
          name="ProfilePage"
          className={css.sellerProfileLink}
          params={{ id: currentSeller?.id?.uuid }}
        >
          <FormattedMessage
            id="CartPage.sellerProfileLink"
            values={{ displayName: sellerDisplayFirstName }}
          />
        </NamedLink>
      </div>
      <div className={css.cartBody}>
        <div className={css.listingsPanel}>
          {listings.map(listing => {
            const listingInCart = cartListings?.find(
              cartListing => cartListing.listingId === listing.id.uuid
            );
            const listingMinimumQty = listing.attributes?.publicData?.minimumOrderQuantity || 1
            const quantity = Number(listingInCart?.quantity ||  listingMinimumQty)
            const variants = listingInCart?.variants;

            return (
              <ListingCard
                key={listing.id.uuid}
                intl={intl}
                listing={listing}
                quantity={quantity}
                variants={variants}
                onRemoveFromCart={onRemoveFromCart}
                onUpdateQuantity={handleUpdateQuantity}
                currentRemoveListingIds={currentRemoveListingIds}
                removeFromCartInProgress={removeFromCartInProgress}
                removeFromCartError={removeFromCartError}
              />
            );
          })}
        </div>
      </div>
      <div className={css.cartFooter}>
        <Button
          className={css.cartButton}
          onClick={() => handleSingleCartPurchase(firstListingId, otherListings, cartTotalPrice)}
        >
          <FormattedMessage id="CartPage.cartButton" values={{ amount: formattedCartTotalPrice }} />
        </Button>
      </div>
    </div>
  );
};

SellerCart.defaultProps = {
  listings: [],
  currentUserCart: [],
  onRemoveFromCart: null,
  currentRemoveListingIds: [],
  removeFromCartInProgress: false,
  removeFromCartError: null,
  handleSingleCartPurchase: null,
  onUpdateQuantity: null,
};

SellerCart.propTypes = {
  intl: intlShape.isRequired,
  listings: arrayOf(propTypes.listing),
  currentRemoveListingIds: arrayOf(number),
  onRemoveFromCart: func.isRequired,
  removeFromCartInProgress: bool.isRequired,
  removeFromCartError: propTypes.error,
  handleSingleCartPurchase: func,
  onUpdateQuantity: func,
};

export default SellerCart;
