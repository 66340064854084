import React from 'react';
import { NamedLink, IconArrowHead } from '../../../..';
import classNames from 'classnames';

import MenuAccordionSubItem from '../MenuAccordionSubItem/MenuAccordionSubItem';
import css from './MenuAccordionItem.module.css';

const MenuAccordionItem = props => {
  const { item, activeItemKey, setActiveItemKey, activeSubItemKey, setActiveSubItemKey } = props;

  // Extracting subitems array from the current menu item
  const subItems = item.options;

  // Checking if the current menu item is active
  const isActiveItemKey = activeItemKey === item.key;

  // Toggling the active menu item
  const toggleActiveItem = key => {
    if (isActiveItemKey) {
      setActiveItemKey(null);
    } else {
      setActiveItemKey(key);
      setActiveSubItemKey(null);
    }
  };

  // Adding classes to the menu item based on its active state
  const itemClasses = classNames(css.accordionItem, {
    [css.activeAccordionItem]: isActiveItemKey,
  });

  return (
    <div className={itemClasses}>
      {/* If subItems exist, display them in an accordion */}
      {subItems ? (
        <div className={css.accordionItemNav} onClick={() => toggleActiveItem(item.key)}>
          <span className={css.itemLabel}>{item.label}</span>
          <IconArrowHead className={css.arrow} direction="right" />
        </div>
      ) : (
        // Otherwise, display the item label as a link
        <NamedLink className={css.accordionItemNav} name="SearchPage" {...item.linkProps}>
          <span className={css.itemLabel}>{item.label}</span>
        </NamedLink>
      )}

      {/* Display subitems if the menu item is active */}
      {isActiveItemKey &&
        subItems?.map(subItem => (
          <MenuAccordionSubItem
            mainItem={item}
            key={subItem.key}
            item={subItem}
            activeSubItemKey={activeSubItemKey}
            setActiveSubItemKey={setActiveSubItemKey}
          />
        ))}
    </div>
  );
};

export default MenuAccordionItem;
