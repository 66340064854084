import React from 'react';
import PropTypes from 'prop-types';

const IconRemove = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#prefix__clip0)" stroke="#000" strokeMiterlimit="10" strokeLinecap="square">
        <path d="M11 1.5l-10 10M11 11.5L1 1.5"></path>
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h12v12H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

IconRemove.defaultProps = {
  className: null,
};

const { string } = PropTypes;

IconRemove.propTypes = {
  className: string,
};

export default IconRemove;
