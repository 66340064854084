import React from 'react';
import { string } from 'prop-types';
import { Tooltip as TooltipComponent } from 'react-tooltip';
import classNames from 'classnames';

// Import modules from this directory
import css from './Tooltip.module.css';

const TOOLTIP_HTML_SYMBOL = '\u24D8';

const Tooltip = props => {
  const { rootClassName, className, symbolClassName, tooltipClassName, id, content } = props;
  const classes = classNames(rootClassName || css.root, className);
  const symbolClasses = classNames(css.symbol, symbolClassName);
  const tooltipClasses = classNames(css.tooltip, tooltipClassName);
  return (
    <span className={classes} data-tooltip-id={id} data-tooltip-content={content}>
      <span className={symbolClasses}>{TOOLTIP_HTML_SYMBOL}</span>
      <TooltipComponent className={tooltipClasses} id={id} />
    </span>
  );
};

Tooltip.defaultProps = {
  // classes
  rootClassName: null,
  className: null,
  symbolClassName: null,
  tooltipClassName: null,

  id: null,
  content: null,
};

Tooltip.propTypes = {
  // classes
  rootClassName: string,
  className: string,
  symbolClassName: string,
  tooltipClassName: string,

  id: string,
  content: string,
};

export default Tooltip;
